import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          John Stockdale
        </p>
        <a
          className="App-link"
          href="mailto:me@johnstockdale.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          founder 🏴‍☠️🏖✨
        </a>
        <span>
          <a
            className="App-link"
            href="/js.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            resume
          </a>
          &nbsp;|&nbsp;
          <a
            className="App-link"
            href="/js.vcf"
            target="_blank"
            rel="noopener noreferrer"
          >
            vcard
          </a>
        </span>
      </header>
    </div>
  );
}

export default App;
